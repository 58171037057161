import { render } from 'preact';
import { signal } from '@preact/signals';
import { LatestLoader } from './Latest';

const getData = async ({ limit = 8, before = '' }) => {
    const url = new URL(`https://www.vg.no/feed/latest`);
    if (limit) {
        url.searchParams.set('limit', limit.toString());
    }
    if (before) {
        url.searchParams.set('before', before);
    }

    const req = await fetch(url.toString());
    const data = await req.json();
    return data;
};

export default async ({ node: rootNode, trackEngagement, device }) => {
    let initialHeight = rootNode.style.height;
    const loading = signal(true);

    const initialData = await getData({});
    const initialDataSignal = signal(initialData); // Store initial data in a signal
    const data = signal(initialData);

    const collapseCompleted = signal(false);
    const moreDataLoaded = signal(false);

    loading.value = false;

    const loadMoreCallback = async (event) => {
        if (loading.value === true) {
            return;
        }

        loading.value = true;
        const last = data.value[data.value.length - 1];
        const newData = await getData({ limit: 10, before: last.published });
        if (newData.length > 0) {
            moreDataLoaded.value = true;
        }
        data.value = [...data.value, ...newData];
        loading.value = false;

        if (event instanceof Event) {
            rootNode.style.height = 'auto';
        }

        trackEngagement({
            elementId: 'vg-widget-latest:show-more-button',
            type: 'Click',
        });
    };
    const collapseCallback = async (event) => {
        if (event instanceof Event) {
            data.value = [...initialDataSignal.value];
            rootNode.style.height = initialHeight;
            collapseCompleted.value = true;
            moreDataLoaded.value = false;
        }
    };

    collapseCompleted.subscribe((isCollapsed) => {
        if (isCollapsed) {
            rootNode.scrollIntoView({ behavior: 'smooth' });
            collapseCompleted.value = false; // Reset the flag
        }
    });

    trackEngagement({
        elementId: 'vg-widget-latest',
        type: 'View',
    });

    rootNode.addEventListener('click', (event) => {
        const { target } = event;
        const link = target.closest('a');

        if (link) {
            trackEngagement({
                elementId: 'vg-widget-latest',
                type: 'Click',
            });
        }
    });

    rootNode.addEventListener('click', (e) => {
        const target = e.target.closest('button[role="tab"]');
        const controls = target.getAttribute('aria-controls');
        trackEngagement({
            elementId: `vg-widget-latest:tabs:${controls}`,
            type: 'Click',
            elementName: target.textContent.trim(),
        });
    });

    return render(
        <LatestLoader
            data={data}
            device={device}
            rootNode={rootNode}
            loadMoreCallback={loadMoreCallback}
            collapseCallback={collapseCallback}
            loading={loading}
            moreDataLoaded={moreDataLoaded}
        />,
        rootNode,
    );
};
